import React, { useState, useEffect, useCallback } from "react";
import useAdminTools from "adminapi";

import { Card, Tab, Tabs, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LoopIcon from "@mui/icons-material/Loop";

import { useForceUpdate } from "customHooks";
import TabContent from "components/Common/TabContent";
import PageHeader from "components/Common/PageHeader";
import ScopePanel from "components/Admin/ScopePanel";
import "./AdminPanel.css";
import { useAlertStack } from "components/Common/AlertStack";

function NewEntryCard(props) {
	return <Card {...props} className="principalCard newEntry">
		<AddIcon /><em>Add New</em>
	</Card>;
}

function UserCard({user, update}) {
	const [name,setName] = useState(user.name);
	const [email,setEmail] = useState(user.email);
	const [editName,setEditName] = useState(!user.name);
	const [editEmail,setEditEmail] = useState(!user.email);
	let namePart, emailPart;

	const handleNameChange = useCallback((event) => {
		let newValue = event.target.value;
		setName(newValue);
		user.name=newValue;
		user.dirty=true;
	},[user]);

	const handleNameKeypress = (event)=>{
		if (event.key === "Enter") {
			setEditName(false);
			event.preventDefault();
		}
	};
	
	const handleNameClick = ()=>{
		setEditName(true);
	};

	const handleFocus = (event)=>{
		event.target.select();
	};

	const handleEmail = useCallback((event) => {
		let val = event.target.value;
		setEmail(val);
		user.email=val;
		user.dirty=true;
	},[user]);
	
	const handleEmailKeypress = (event)=>{
		if (event.key === "Enter") {
			setEditEmail(false);
			event.preventDefault();
		}
	};
	
	const handleEmailClick = ()=>{
		setEditEmail(true);
	};

	if(editName) {
		namePart = <div className="namePart">
			<TextField fullWidth type="input"
				size="small"
				label="Name"
				autoFocus
				value={name}
				onChange={handleNameChange}
				onFocus={handleFocus}
				onKeyDown={handleNameKeypress}
				InputProps={{
					spellCheck: false,
				}}
			/>
		</div>;
	} else {
		namePart = <div className="namePart" 
			title={user.name}
			onClick={handleNameClick}
		>
			{user.name}
		</div>;
	}
	if(editEmail) {
		emailPart = <div className="emailPart">
			<TextField fullWidth type="input"
				size="small"
				label="Email Address"
				autoFocus
				value={email}
				onChange={handleEmail}
				onFocus={handleFocus}
				onKeyDown={handleEmailKeypress}
				InputProps={{
					spellCheck: false,
				}}
			/>
		</div>;
	} else {
		emailPart = <span className="emailPart" 
			title={user.email}
			onClick={handleEmailClick}
		>
			{user.email}
		</span>;
	}
	return <Card className="principalCard" data-dirty={user.dirty}>
		{namePart}
		{emailPart}
		<ScopePanel user={user} update={update}/>
	</Card>;
}

function ApiKeyCard({apikey, update}) {
	const [name,setName] = useState(apikey.name);
	const [key,setKey] = useState(apikey.key);
	const [editName,setEditName] = useState(!apikey.name);
	const [editKey,setEditKey] = useState(!apikey.name);
	let namePart, rotatePart;

	const handleNameChange = useCallback((event) => {
		let val = event.target.value;
		setName(val);
		apikey.name=val;
		apikey.dirty=true;
	},[apikey]);

	const handleNameKeypress = (event)=>{
		if (event.key === "Enter") {
			setEditName(false);
			event.preventDefault();
		}
	};
	
	const handleNameClick = ()=>{
		setEditName(true);
	};

	const handleFocus = (event)=>{
		event.target.select();
	};

	const handleKeyChange = useCallback((event) => {
		let val = event.target.value;
		if(val !== "revoke") {
			setKey(val);
			apikey.key=val;
			apikey.dirty=true;
		}
	},[apikey]);

	const handleKeyKeypress = useCallback(event=>{
		if (event.key === "Enter") {
			apikey.key=key;
			setEditKey(false);
			event.preventDefault();
		}
	},[apikey]);
	
	const handleKeyRotate = ()=>{
		setEditKey(true);
		setKey("");
	};

	const handleKeyRevoke = useCallback(()=>{
		setKey("revoke");
		apikey.key="revoke";
		apikey.dirty=true;
	},[apikey]);

	const handleKeyGenerate = ()=>{
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
		let result = "";
		const randomValues = new Uint8Array(27);
		crypto.getRandomValues(randomValues);
		for(let i = 0; i < randomValues.length;i+=3) {
			const part1 = randomValues[i  ] & ((1<<6)-1);
			let part2   = randomValues[i  ] >> 6;
			part2      += (randomValues[i+1] & ((1<<4)-1)) << 2;
			let part3   = randomValues[i+1] >> 4;
			part3      += (randomValues[i+2] & ((1<<2)-1)) << 4;
			const part4 = randomValues[i+2] >> 2;
			result += chars[part1]+chars[part2]+chars[part3]+chars[part4];
		}
		apikey.dirty=true;
		setKey(result);
	};

	if(editName) {
		namePart = <div className="namePart">
			<TextField fullWidth type="input"
				size="small"
				autoFocus
				label="Name"
				value={name}
				onChange={handleNameChange}
				onFocus={handleFocus}
				onKeyDown={handleNameKeypress}
				InputProps={{
					spellCheck: false,
				}}
			/>
		</div>;
	} else {
		namePart = <div className="namePart" 
			title={apikey.name}
			onClick={handleNameClick}
		>
			{apikey.name}
		</div>;
	}
	if(editKey) {
		rotatePart = <TextField fullWidth type="input"
			size="small"
			autoFocus
			label="New API Key"
			value={key}
			onChange={handleKeyChange}
			onFocus={handleFocus}
			onKeyDown={handleKeyKeypress}
			InputProps={{
				spellCheck: false,
				endAdornment:<InputAdornment position="end">
					<IconButton
						onClick={handleKeyGenerate}
					>
						<LoopIcon className="generateIcon" />
					</IconButton>
				</InputAdornment>
			}}
		/>;
	} else if(key === "revoke"){
		rotatePart = "KEY REVOKED";
	} else {
		rotatePart = <>
			<Button variant="outlined" onClick={handleKeyRotate}>Rotate</Button>
			{" "}
			<Button variant="contained" onClick={handleKeyRevoke}>Revoke</Button>
			{key === undefined ? "" : " *Rotated"}
		</>;
	}
	let className = "principalCard";
	if(apikey.key === "revoke") {
		className += " revoked";
	}
	return <Card className={className} data-dirty={apikey.dirty}>
		{namePart}
		<div className="rotatePart">{rotatePart}</div>
		<ScopePanel apikey={apikey} update={update}/>
	</Card>;
}

export default function AdminPanel(/*{props}*/) {
	const api = useAdminTools();
	const [adminData,setAdminData] = useState(null);
	const [errorMessage,setErrorMessage] = useState(null);
	const [saving,setSaving] = useState(false);

	const [currentTab, setCurrentTab] = useState(0);
	const [AlertStack,newAlert] = useAlertStack();
	const forceUpdate = useForceUpdate();
	
	useEffect(() => {
		api.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin`,{headers: api.noCache}).then(response => {
			const adminData = response.data.data;
			console.log(adminData);
			for(const user of adminData.users) {
				user.scope = user.scope.split(/\s+/);
				if(user.scope.includes("admin")) {
					user.scope = ["admin"];
				}
			}
			for(const apikey of adminData.apikeys) {
				apikey.scope = apikey.scope.split(/\s+/);
				if(apikey.scope.includes("admin")) {
					apikey.scope = ["admin"];
				}
			}
			setAdminData(adminData);
		}).catch(error => {
			setErrorMessage(`Error getting activity: ${error.statusText ?? error?.toString()}`);
		});
	}, []);

	const handleTabChange = useCallback((_, newTab) => {
		setCurrentTab(newTab);
	},[]);

	const addUser = useCallback(()=>{
		adminData.users.push({
			name:"",
			email:"",
			scope:[],
			dirty:true,
		});
		forceUpdate();
	},[adminData]);

	const addApikey = useCallback(()=>{
		adminData.apikeys.push({
			name:"",
			key:"",
			scope:[],
			dirty:true,
		});
		forceUpdate();
	},[adminData]);

	const handleSave = useCallback(()=>{
		if(saving) {
			return;
		}
		console.log(adminData);
		let users = adminData.users.filter(u=>u.dirty);
		let apikeys = adminData.apikeys.filter(k=>k.dirty);
		console.log(users,apikeys);
		if(users.length + apikeys.length === 0) {
			newAlert("info", "No changes to save");
			return;
		}
		if(users.some(u=>!u.email)) {
			newAlert("error", "Users must have an email address");
			return;
		}
		if(apikeys.some(k=>!k.key)) {
			newAlert("error", "Api Keys may not be empty");
			return;
		}

		const data = {
			users: users.map(u=>{
				let ru = {
					name: u.name,
					email: u.email,
					scope: u.scope.join(" "),
				};
				if(u.id) {
					ru.id = u.id;
				}
				return ru;
			}),
			apikeys: apikeys.map(k=>{
				let rk = {
					name: k.name,
					key: k.key,
					scope: k.scope.join(" "),
				};
				if(k.id) {
					rk.id = k.id;
				}
				return rk;
			}),
		};
		api.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin`,{data}).then(response => {
			console.log(response);
			setErrorMessage("Changes Saved");
		}).catch(error => { 
			newAlert("error", `Error: ${error.statusText ?? error?.toString()}`, "Error Saving Changes");
		}).finally(()=>{
			setSaving(false);
		});
		setSaving(true);
	},[adminData,saving]);
	
	let content;
	if(errorMessage) {
		content = errorMessage;
	} else if(adminData) {
		content = <>
			<Tabs
				value={currentTab}
				onChange={handleTabChange}
				centered
			>
				<Tab label="Users" />
				<Tab label="API Keys" />
			</Tabs>
			<TabContent index={0} value={currentTab}>
				{
					adminData.users.map((u,i)=><UserCard key={"u"+i} user={u} update={forceUpdate}/>)
				}
				<NewEntryCard onClick={addUser}/>
			</TabContent>
			<TabContent index={1} value={currentTab}>
				{
					adminData.apikeys.map((k,i)=><ApiKeyCard key={"k"+i} apikey={k} update={forceUpdate}/>)
				}
				<NewEntryCard onClick={addApikey}/>
			</TabContent>
			<br />
			<div className="savebtn">
				<Button variant="contained" disabled={saving} onClick={handleSave}>Save Changes</Button>
			</div>
		</>;
	} else {
		content = "Loading...";
	}

	return <>
		<PageHeader title={"Admin Panel"} />
		<div className="adminPanel">
			{content}
		</div>

		<AlertStack />
	</>;
}