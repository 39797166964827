export function dateFormat(inputDate) {
	let date, month, year;

	date = inputDate.getDate();
	if (isNaN(date)) {
		return " ~ ";//invalid date
	}
	month = inputDate.getMonth() + 1;
	year = inputDate.getFullYear();

	date = date.toString().padStart(2, "0");
	month = month.toString().padStart(2, "0");

	return `${month}/${date}/${year}`;
}

export function hyphenatedDate (date) {
	const yyyy = (""+date.getFullYear()).padStart(4,"0");
	const mm = (""+date.getMonth()).padStart(2,"0");
	const dd = (""+date.getDate()).padStart(2,"0");

	return `${yyyy}-${mm}-${dd}`;
}

const tzOffset = new Date().getTimezoneOffset();

//While a Date objcect is nothing more than
//a number of ms since the epoch, it doesn't exactly
//play well with a date picker.
//
//These functions add/subtract the timezone offset so that
//date pickers can show the actual dates. 
export function utcToLocal(value) {
	if (value) {
		return new Date(value.getTime() + minutesToMs(tzOffset));
	} else {
		return value;
	}
}

export function localToUtc(value) {
	if (value) {
		return new Date(value.getTime() - minutesToMs(tzOffset));
	} else {
		return value;
	}
}

export function isValidDate(inputDate) {
	return (inputDate instanceof Date) && !isNaN(inputDate);
}

export function minutesToMs(minutes) {
	return minutes * 60 * 1000;
}

export function hoursToMs(hours) {
	return hours * 60 * 60 * 1000;
}

export function daysToMs(days) {
	return days * 24 * 60 * 60 * 1000;
}

export function weeksToMs(weeks) {
	return weeks * 7 * 24 * 60 * 60 * 1000;
}