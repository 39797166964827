import React, { Fragment, useState, useCallback } from "react";

import { TextField, Autocomplete, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import "./ScopePanel.css";

const apiScopeList = [
	"cron",
	"bridge",
	"usage",
	//"admin",
];

const userScopeList = [
	"read",
	"write",
	"tempcode",
	"report",
	//"zohoBulk",
	"admin",
];

function ScopeBadge({scope, principal, update}) {
	()=>principal;
	const handleRemove = useCallback(()=>{
		const index = principal.scope.indexOf(scope);
		if(index !== -1) {
			principal.scope.splice(index, 1);
			principal.dirty = true;
		}
		update();
	},[principal.scope, scope, update]);
	return <div className="scopeBadge">
		{""+scope}
		<IconButton
			size="small"
			onClick={handleRemove}
		>
			<CloseIcon fontSize="small"/>
		</IconButton>
	</div>;
}

function AddNewBadge({principal, ...props}) {
	()=>principal;
	return <div {...props} className="scopeAddBadge">
		<IconButton size="small"><AddIcon fontSize="small"/></IconButton>
	</div>;
}

export default function ScopePanel({user, apikey, update}) {
	const [newScope,setNewScope] = useState(null);
	const scopeList = apikey ? apiScopeList : userScopeList;
	const principal = apikey ?? user;

	const handleNewScope = useCallback(() => {
		setNewScope("");
	},[]);

	const confirmScopes = useCallback((newScope)=>{
		setNewScope(()=>{
			const newScopes = newScope.trim().split(/\s+/);
			for(let scope of newScopes) {
				if(scope === "") {
					continue;
				}
				if(scope === "tempcode") {
					scope = "tempcode2";
				}

				principal.dirty = true;

				if(scope === "admin") {
					principal.scope = ["admin"];
					break;
				} else if(!principal.scope.includes(scope)) {
					principal.scope.push(scope);
				}
			}
			return null;
		});
	},[principal]);

	const handleNameKeypress = useCallback((event)=>{
		if (event.key === "Enter") {
			confirmScopes(newScope);
			event.preventDefault();
		}
	},[newScope]);

	const handleChange = useCallback((event,newValue,reason) => {
		setNewScope(newValue??"");
		if(reason === "selectOption") {
			const partial = principal.scope.indexOf(newScope);
			if(partial !== -1) {
				principal.scope.splice(partial,1);
			}
			confirmScopes(newValue);
		}
	},[newScope]);

	const badges = principal.scope.map(
		s=><Fragment key={s}><ScopeBadge scope={s} principal={principal} update={update}/>{" "}</Fragment>
	);
	let newScopeBadge = undefined;
	if(newScope === null) {
		if(!principal.scope.includes("admin")) {
			newScopeBadge = <AddNewBadge onClick={handleNewScope} principal={principal}/>;
		}
	} else {
		newScopeBadge = <Autocomplete
			className="newScope"
			freeSolo
			disablePortal
			disableClearable
			value={newScope}
			onChange={handleChange}
			inputValue={newScope}
			onInputChange={handleChange}
			onKeyDown={handleNameKeypress}
			options={scopeList}
			renderInput={params => <TextField {...params} autoFocus variant="standard"/>}
		/>;
	}
	return <div className="scopePanel" data-dirty={principal.dirty}>
		{badges}
		{newScopeBadge}
	</div>;
}