//used for when one scope implies other scopes
//for instance, write access also grants read access
const impliedScopes = new Map([
	["write",["read"]],
]);

//returns an array of scopes allowed by the session
export function sessionScope() {
	let fullScope = localStorage.getItem("login/scope")??"";
	
	let individualScopes = fullScope.split(/\s+/)
		.map(str => [ ...(impliedScopes.get(str) ?? []), str])
		.flat();

	if(individualScopes.includes("admin")) {
		return ["admin"];
	}

	return individualScopes;
}

//returns true if all of the given scopes exist in the session's scope string.
export function sessionHasScope(...scopes) {
	let fullScope = sessionScope();

	if(fullScope.includes("admin")) {
		return true;
	}

	for (let s of scopes) {
		if (!fullScope.includes(s)) {
			return false;
		}
	}
	return true;
}


//When given a string 'str', returns an integer from each occurence of scope 'strX' in the session's scope
//for example, from the scope ["tempcode2","write","tempcode14"], this function will return [2, 14].
//
//As a special case, if the user is an admin, the string "admin" will be returned instead.
export function sessionGetAllScopeValues(scope) {
	let fullScope = sessionScope();

	if(fullScope.includes("admin")) {
		return "admin";
	}

	let scopePattern = new RegExp(`^${scope}(\\d+)$`);

	let valuedScopes = fullScope.map(
		//match returns in the form ["scopeNUM", "NUM"]
		//we want to do +"NUM", so always access index 1 in the array
		scope => +(scope.match(scopePattern)?.[1])
	).filter(scope=>scope); //filter out resulting NaNs

	return valuedScopes;
}