export const LicenseTypes = {
	NONE: "",
	EDIT: "Edit",
	STUDENT: "Student",
	TRAN: "Tran",
	NOECLIPSE: "NoEclipse",
};

export const LicenseOptions = {
	BRIDGE: "Bridge",
	BRIDGE_CLOUD: "BridgeCloud",
	RSR: "RSR",
	ACCUCAP: "AccuCap",
	STENO: "Steno",
	VOX: "Vox",
};

export const SpeechServices = {
	GLOBAL: "global",
	GOOGLE_SPEECH: "google_speech",
	SPEECHMATICS: "speechmatics",
	WATSON: "watson",
	AZURE: "azure",
	KENSHO: "kensho",
};