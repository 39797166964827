import React, { useCallback, useState } from "react";

import { Button, IconButton, TextField, MenuItem, Card, CardActions, CardContent, CircularProgress } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import useAdminTools from "adminapi";
import { sessionGetAllScopeValues } from "session/scope";
import PageHeader from "components/Common/PageHeader";
import "./CreateTempcode.css";
import { useAlertStack } from "components/Common/AlertStack";

const CodeTypes = {
	NONE: "",
	EXECUTE: "execute",
	REKEY: "rekey",
	UNLOCK: "unlock",
};


function CreateTempcode(/*{props}*/) {
	const api = useAdminTools();

	//session state
	let maxNumDays = sessionGetAllScopeValues("tempcode");
	if(maxNumDays === "admin") {
		maxNumDays = Infinity;
	} else {
		maxNumDays = Math.max(...maxNumDays);
	}

	//form state
	const [usercode, setUsercode] = useState("");
	const [majorVersion, setMajorVersion] = useState("");
	const [numDays, setNumDays] = useState(2);
	const [codeType, setCodeType] = useState(CodeTypes.EXECUTE);

	//page state
	const [title,] = useState("Generate a Tempcode");
	const [AlertStack, newAlert] = useAlertStack();
	const [waiting, setWaiting] = useState(null);
	const [tempcode, setTempcode] = useState(null);

	const copyTempcode = useCallback(() => {
		navigator.clipboard.writeText(tempcode).then(() => {
			newAlert("info", "Tempcode copied to clipboard");
		}, (error) => {
			newAlert("error", "Copy failed: " + error.toString());
		});
	}, [tempcode]);

	const handleCodeType = (event) => {
		setCodeType(event.target.value);
	};

	const handleUsercode = (event) => {
		let val = event.target.value;
		if (event.target.validity.valid) {
			setUsercode(val.toUpperCase());
		}
	};

	const handleNumDays = (event) => {
		setNumDays(event.target.value);
	};

	const handleMajorVersion = (event) => {
		let val = event.target.value;
		if (event.target.validity.valid) {
			setMajorVersion(val);
		}
	};

	const submitRequest = () => {
		const tempcodeGetUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/tempcode`
            + `?usercode=${encodeURIComponent(usercode)}`
            + `&major_version=${encodeURIComponent(majorVersion)}`
            + `&codetype=${encodeURIComponent(codeType)}`
            + `&num_days=${encodeURIComponent(numDays)}`;

		setWaiting("submit");

		api.get(tempcodeGetUrl).then(response => {
			if (response.status === 200) {
				return response;
			} else {
				throw response;
			}
		}).then(response => {
			setTempcode(response.data.data.tempcode);
		}).catch(error => {
			newAlert("error", <>Could not generate tempcode, received error:<br />{error?.toString()}</>, "Error");
		}).finally(() => {
			setWaiting(null);
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (waiting)
			return;

		let validationErrors = [];

		if (usercode === "") {
			validationErrors.push(<li key="usercodeReq">Usercode is required</li>);
		} else if (usercode.length !== 4) {
			validationErrors.push(<li key="usercodeSize">Usercode must be 4 letters long</li>);
		}

		if (codeType === CodeTypes.NONE) {
			validationErrors.push(<li key="codeTypeReq">Code Type is required</li>);
		}

		if (majorVersion === "") {
			validationErrors.push(<li key="majorVersionReq">Major Version is required</li>);
		}

		if (validationErrors.length !== 0) {
			newAlert("error", <ul>{validationErrors}</ul>, "Invalid Fields");
		} else {
			submitRequest();
		}
	};

	let numDaysItems = React.useMemo(() => {
		const items = [
			[2, "2 Days"],
			[7, "1 Week"],
			[14, "2 Weeks"],
			[35, "35 Days"],
			[365, "1 Year"],
		];

		let ret = items.filter(item => item[0] <= maxNumDays).map(item =>
			<MenuItem key={item[0].toString()} value={item[0]}>{item[1]}</MenuItem>
		);

		return ret;

	}, [maxNumDays]);

	if(maxNumDays <= 0) {
		return <>
			<div>Permission Denied</div>
			<br />
			<a href='/'>Back to dashboard</a>
		</>;
	}

	let tempcodeGen;
	if (tempcode) {
		let genBtn;
		if (waiting) {
			genBtn = <IconButton size='small'>
				<CircularProgress disableShrink size="1.5rem" />
			</IconButton>;
		} else {
			genBtn = <Button
				type='submit'
				size='small'
				className="genButton"
			>
				Generate Another
			</Button>;
		}
		tempcodeGen = <Card
			variant='outlined'
			className="result"
		>
			<CardContent>
				<span>Tempcode generated:</span>
				<br />
				<span className="tempcode">{tempcode}</span>
			</CardContent>
			<CardActions disableSpacing>
				{genBtn}
				<Button
					size='small'
					className="copyButton"
					onClick={copyTempcode}
				>
					Copy to Clipboard
				</Button>
			</CardActions>
		</Card>;

	} else {
		let tempcodeContent;
		if (waiting==="submit") {
			tempcodeContent = <CircularProgress disableShrink size="1.5rem" />;
		} else {
			tempcodeContent = <><AddBoxIcon className="doneIcon" />Generate Tempcode</>;
		}
		tempcodeGen = <Button
			type='submit'
			fullWidth
			disabled={Boolean(waiting)}
			variant='outlined'
		>
			{tempcodeContent}
		</Button>;
	}

	return <>
		<PageHeader title={title} />
		<div className="tempcodeForm">
			<form autoComplete='off' onSubmit={handleSubmit}>
				<TextField
					className="tempcodeItem"
					label="Usercode"
					variant='outlined'
					size='small'
					//
					inputProps={{
						pattern: "[a-zA-Z]{0,4}",
						value: usercode,
					}}
					onChange={handleUsercode}
				/>
				<TextField
					className="tempcodeItem"
					label="Major Version"
					variant='outlined'
					size='small'
					//
					inputProps={{
						pattern: "\\d{0,2}",
						value: majorVersion,
					}}
					onChange={handleMajorVersion}
				/>
				<TextField select
					className=" tempcodeItem"
					value={codeType}
					label="Code Type"
					variant='outlined'
					size='small'
					onChange={handleCodeType}
				>
					<MenuItem value={CodeTypes.NONE}>&nbsp;</MenuItem>
					<MenuItem value={CodeTypes.EXECUTE}>Run Eclipse</MenuItem>
					<MenuItem value={CodeTypes.REKEY}>Change Hardware Key</MenuItem>
					<MenuItem value={CodeTypes.UNLOCK}>Unlock Content</MenuItem>
				</TextField>
				<TextField select
					className=" tempcodeItem"
					value={numDays}
					label="Code Expiration"
					size='small'
					onChange={handleNumDays}
				>
					{numDaysItems}
				</TextField>
				<div className="tempcodeItem">
					{tempcodeGen}
				</div>
			</form>
		</div>

		<AlertStack />
	</>;
}

export default CreateTempcode;
